<!-- 首页导航栏页面 -->

<template>
     <!-- LOGO -->
     <!-- <div class="logo-container" @click="goToHome">
    <el-image class="logo" :src="require('@/assets/logo.png')" style="height: 36px;" fit="fill"/>
    <div class="logo-text">天豐工程有限公司</div>
    </div> -->
   
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="isEllipsis"
    @select="handleSelect"
    background-color="#0c0c0d"
    text-color="#ffffff"
    active-text-color="#ffffff"
    style="border-bottom: 0px;
    height: 60px;
    caret-color: transparent;
    position: fixed;
  top: 0;
  /* 顶部粘着 */
  left: 0;
  /* 左侧粘着 */
  right: 0;
  /* 右侧粘着 */"
  router
  >
  
  <el-menu-item index="/main">
      <img
        style="width: 36px ;height: 36px" 
        src="@/assets/logo.png" 
        alt="Element logo"
      />天豐工程有限公司
    </el-menu-item>

  <div class="flex-grow"></div>
  <el-menu-item index="/elec">電器工程</el-menu-item>
  <el-menu-item index="/cooler">冷電工程</el-menu-item>
  <el-menu-item index="/renovation">裝修工程</el-menu-item>
    <!-- <el-sub-menu index="2">
      <template #title>電器工程</template>
      <el-menu-item index="/engineering">Railways</el-menu-item>
      <el-menu-item index="2-2">Community</el-menu-item>
      <el-menu-item index="2-3">Residential</el-menu-item>
      <el-menu-item  index="2-4">Commercial</el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3">
      <template #title>冷電工程</template>
      <el-menu-item index="3-1">Railways</el-menu-item>
      <el-menu-item index="3-2">Community</el-menu-item>
      <el-menu-item index="3-3">Residential</el-menu-item>
      <el-menu-item  index="3-4">Commercial</el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="4">
      <template #title>裝修工程</template>
      <el-menu-item index="4-1">Railways</el-menu-item>
      <el-menu-item index="4-2">Community</el-menu-item>
      <el-menu-item index="4-3">Residential</el-menu-item>
      <el-menu-item  index="4-4">Commercial</el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="5">
      <template #title>關於我們</template>
      <el-menu-item index="/aboutus">工作室</el-menu-item>
      <el-menu-item index="5-2">認定資格</el-menu-item>
      <el-menu-item index="5-3">研究中心</el-menu-item>
      <el-menu-item  index="5-4">好地方規定</el-menu-item>
    </el-sub-menu> -->
    <!-- <el-menu-item index="/aboutus">關於我們</el-menu-item> -->
    <el-menu-item index="/zhizi">公司資質</el-menu-item>
    <el-menu-item index="/shop">天豐電器五金</el-menu-item>
  </el-menu>

</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      activeIndex:'',//
      isEllipsis: false, // 
    };
  },
  watch: {
    $route() {
      this.setCurrentRoute();
    }
  },
  computed: {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    setCurrentRoute() {
      this.activeIndex= this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
    },
    handleResize() {
      this.isEllipsis = window.innerWidth < 1000;
    },
  },
  created() {
    this.setCurrentRoute();

  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style scoped>

.flex-grow {
  flex-grow: 1;
  min-width: 120px;
}
.logo-container {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: 0 10px;
  caret-color: transparent;
}
.logo-text {
  font-size: 20px; /* 样式化文本大小和其他样式 */
  margin-left: 10px; /* 根据需要调整文本与图像之间的距离 */
  color: #ffffff; /* 文本颜色 */
}



</style>